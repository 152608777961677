import { startTransition, useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';
import { KeyLoader } from 'swr';
import useSWRInfinite from 'swr/infinite';

import { ParsedProductsApiResponse } from '@hultafors/shared/types';

import { SEARCH_PAGE_SIZE } from '@hultafors/eripiowear/api';
import { useGlobal } from '@hultafors/eripiowear/hooks';
import {
  EripiowearProduct,
  ProductSearchResultsFragment,
} from '@hultafors/eripiowear/types';

import { Loader } from '../loader/loader';
import { Pager } from '../pager/pager';
import { Products } from '../products/products';
import { SearchInput } from '../search-input/search-input';
import { Section } from '../section/section';

import {
  Header,
  MessageWrapper,
  ProductGrid,
  SearchInputWrapper,
  StyledProductSearchResults,
  Title,
} from './product-search-results.styled';

/* eslint-disable-next-line */
interface ProductSearchResultsProps extends ProductSearchResultsFragment {}

export function ProductSearchResults({ title }: ProductSearchResultsProps) {
  const { globalContent, settings } = useGlobal();
  const { locale, ...router } = useRouter();
  const params = new URLSearchParams(router.query as Record<string, string>);
  const searchTerm = params.get('q') as string;
  const [term, setTerm] = useState<string>(searchTerm);
  const [searchValue, setSearchValue] = useState<string>(searchTerm);

  const searchListApiBaseUrl = useMemo(() => {
    return `/api/searchlist/${settings.pt?.searchSection}`;
  }, [settings]);

  // Creates api path that also acts as cache key for swr
  const getKey: KeyLoader = (
    index: number,
    previousPageData: ParsedProductsApiResponse<EripiowearProduct> | null
  ) => {
    if (
      previousPageData &&
      previousPageData.paging.pageCount === previousPageData.paging.pageNumber
    ) {
      return null;
    }

    const params = new URLSearchParams({
      includeFilter: 'false',
      pageNumber: `${index + 1}`,
      pageSize: `${SEARCH_PAGE_SIZE}`,
      searchValue,
      ...(locale && locale !== 'com' ? { locale } : {}),
    });
    return `${searchListApiBaseUrl}?${params.toString()}`;
  };

  // Initialize swr for search results fetching
  const { data, isValidating, isLoading, size, setSize } =
    useSWRInfinite<ParsedProductsApiResponse<EripiowearProduct>>(getKey);

  const products = useMemo(() => {
    return [...(data?.flatMap(({ items }) => items) || [])];
  }, [data]);

  const pagerData = useMemo(() => {
    const length = data?.length || 0;
    return data?.[length - 1]?.paging;
  }, [data]);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    startTransition(() => {
      setTerm(event.target.value);
    });
  };

  const loadMore = () => {
    const newSize = size + 1;
    startTransition(() => {
      setSize(newSize);
    });
    const params = new URLSearchParams(router.query as Record<string, string>);
    params.delete('category');
    if (!newSize || newSize === 1) {
      params.delete('page');
    } else {
      params.set('page', `${newSize}`);
    }
    const query = params.toString();
    const path = router.asPath.split('?')[0];
    router.push(`${path}${query && `?${query}`}`, undefined, {
      shallow: true,
    });
  };

  const onSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    startTransition(() => {
      setSearchValue(term);
    });
  };

  const clear = () => {
    startTransition(() => {
      setTerm('');
      // setSearchValue('');
    });
  };

  useEffect(() => {
    if (searchValue) {
      const params = new URLSearchParams(
        router.query as Record<string, string>
      );
      if (params.get('q') !== searchValue) {
        params.set('q', searchValue);
        params.delete('page');
        const query = params.toString();
        const path = router.asPath.split('?')[0];
        router.push(`${path}${query && `?${query}`}`, undefined, {
          shallow: true,
        });
        startTransition(() => {
          setSize(1);
        });
      }
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValue) {
      startTransition(() => {
        setSize(1);
      });
    }
  }, []);

  const message = useMemo<string>(() => {
    if (
      searchValue &&
      !isLoading &&
      data?.flatMap(({ items }) => items)?.length
    ) {
      const length = data?.length || 0;
      return (
        globalContent?.searchResultCount
          ?.replace('$1', `${data?.[length - 1]?.paging?.itemCount}`)
          .replace('$2', searchValue) || ''
      );
    }
    if (
      searchValue &&
      !isLoading &&
      !data?.flatMap(({ items }) => items)?.length
    ) {
      return (
        globalContent?.noSearchResults?.replace('$1', searchValue) || '' || ''
      );
    }
    return '';
  }, [searchValue, isLoading, data, globalContent]);

  return (
    <StyledProductSearchResults>
      <Header>
        <Title>{title}</Title>
        <SearchInputWrapper>
          <SearchInput
            value={term}
            onSubmit={onSubmit}
            onChange={onChange}
            onClear={clear}
            placeholder={globalContent?.searchBoxPlaceholder}
            loading={isLoading}
          />
        </SearchInputWrapper>
        <MessageWrapper>
          {isLoading && <Loader tiny center />}
          {!isLoading && message}
        </MessageWrapper>
      </Header>
      <Section>
        {!isLoading && !!products.length && (
          <>
            <ProductGrid>
              <Products
                products={products}
                badgeText={globalContent?.new || ''}
                slugs={['products']}
              />
            </ProductGrid>
            <Pager
              buttonText={globalContent.loadMoreButton || ''}
              ofText={globalContent.pagerOf || ''}
              productsText={globalContent.pagerProductsLabel || ''}
              onClick={loadMore}
              pagerData={pagerData}
              loading={isValidating}
            />
          </>
        )}
      </Section>
    </StyledProductSearchResults>
  );
}
