import {
  createContext,
  Dispatch,
  memo,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { ApiSettings } from '@hultafors/shared/types';

import { GlobalFields } from '@hultafors/eripiowear/types';

export interface GlobalContextValue extends GlobalFields {
  settings: ApiSettings;
}

export interface GlobalContextValueWithDispatch extends GlobalContextValue {
  dispatch: Dispatch<GlobalAction>;
}

export const GlobalContext =
  createContext<GlobalContextValueWithDispatch | null>(null);

export interface GlobalProviderProps {
  children?: React.ReactNode;
  value: GlobalFields;
  settings: ApiSettings;
}

type GlobalActionType = 'set';

interface GlobalAction {
  type: GlobalActionType;
  payload: Partial<GlobalContextValue>;
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({
  children,
  value,
  settings,
}) => {
  function reducer(state: GlobalContextValue, action: GlobalAction) {
    switch (action.type) {
      case 'set':
        return { ...state, ...action.payload };
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    ...value,
    settings,
  });

  const [appInsights, setAppInsights] = useState<ApplicationInsights>();

  useEffect(() => {
    if (!appInsights && process.env['APPINSIGHTS_INSTRUMENTATIONKEY']) {
      setAppInsights(
        new ApplicationInsights({
          config: {
            instrumentationKey: process.env['APPINSIGHTS_INSTRUMENTATIONKEY'],
          },
        })
      );
    }
  }, [settings]);

  useEffect(() => {
    if (appInsights) {
      appInsights.loadAppInsights();
      appInsights.trackPageView();
    }
  }, [appInsights]);

  useEffect(() => {
    if (value) {
      dispatch({ payload: value, type: 'set' });
    }
  }, [value]);

  return (
    <GlobalContext.Provider value={{ ...state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const GlobalProviderMemo = memo(GlobalProvider);
