import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/eripiowear/helpers';

import { H1 } from '../h1/h1';
export const StyledProductSearchResults = styled.div``;

export const Header = styled.div`
  padding-block: 30px;
  padding-inline: 20px;
  background-color: ${colors.gray5};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const SearchInputWrapper = styled.div`
  max-width: 500px;
  width: 100%;
`;

export const Title = styled(H1)`
  margin: 0;
  margin-block-end: 20px;
`;

export const ProductGrid = styled.div`
  max-width: ${breakpoints.desktopLarge};
  width: 100%;
  margin-inline: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-block-end: ${spacing.small};

  @media screen and (min-width: ${breakpoints.desktop}) {
    gap: 30px;
  }

  > * {
    background-color: ${colors.white};
    margin: 0;
    inline-size: 100%;

    @media screen and (min-width: ${breakpoints.mediumMobile}) {
      max-inline-size: calc(50% - ${20 * 0.5}px);
    }

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      max-inline-size: calc(${100 / 3}% - ${(20 / 3) * 2}px);
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      max-inline-size: calc(25% - 22.5px);
    }
  }
`;

export const MessageWrapper = styled.div`
  margin-block-start: 20px;
  height: 24px;
`;
