import styled, { css } from 'styled-components';

import { colors } from '@hultafors/eripiowear/helpers';

interface ColorImageWrapperProps {
  $productColorImageSize?: number;
  $selected?: boolean;
  $active?: boolean;
}

export const ColorImageWrapper = styled.span<ColorImageWrapperProps>`
  appearance: none;
  background: transparent;
  position: relative;
  padding: 0;
  line-height: 0;
  cursor: ${({ $active }) => ($active ? 'pointer' : 'inherit')};
  display: inline-flex;
  width: ${({ $productColorImageSize }) => $productColorImageSize || 32}px;
  height: ${({ $productColorImageSize }) => $productColorImageSize || 32}px;
  border-radius: 100%;
  overflow: hidden;
  border: thin solid ${colors.gray4};
  ${({ $selected }) =>
    $selected
    && css`
      box-shadow: 0 0 0 2px ${colors.white}, 0 0 0 3px ${colors.black};
    `}

  img {
    inline-size: 100%;
  }
`;
