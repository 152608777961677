import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/eripiowear/helpers';

export const EmptyProductStyled = styled.div`
  margin-block-end: ${spacing.small};

  @media screen and (min-width: ${breakpoints.bigMobile}) {
    margin-block-end: ${spacing.medium};
  }

  .ImagePlaceholder {
    block-size: 200px;
    background: ${colors.gray5};

    @media screen and (min-width: ${breakpoints.bigMobile}) {
      block-size: 280px;
    }

    @media screen and (min-width: ${breakpoints.tablet}) {
      block-size: 400px;
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      block-size: 300px;
    }

    @media screen and (min-width: ${breakpoints.desktopLarge}) {
      block-size: 400px;
    }
  }

  .ProductInfoPlaceholder {
    padding: 15px 0 0;

    .ProductDetailsPlaceholder {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      span {
        background-color: ${colors.gray5};
        display: flex;
        block-size: 12px;
        margin-block-end: 10px;

        &:nth-of-type(1) {
          inline-size: 40px;
        }

        &:nth-of-type(2) {
          inline-size: 110px;
        }
      }
    }

    .ProductNamePlaceholder {
      background-color: ${colors.gray5};
      display: block;
      block-size: 18px;
    }
  }
`;
