import Link from 'next/link';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import styled, { css } from 'styled-components';

import { colors } from '@hultafors/eripiowear/helpers';

import ChevronSvg from '../../svg/chevron.svg';

interface MenuProps {
  $menu?: boolean;
}

const AccordionLinkShared = css<MenuProps>`
  display: flex;
  border-top: thin solid ${colors.gray4};
  border-top: ${({ $menu }) => ($menu ? '0' : `thin solid ${colors.gray4}`)};
  inline-size: 100%;
  padding: ${({ $menu }) => ($menu ? '8px' : '1.5rem')} 0;
  min-block-size: 48px;
  cursor: pointer;
  position: relative;
  margin: 0;
`;

export const AccordionLinkStyled = styled(Link)<MenuProps>`
  ${AccordionLinkShared};
`;

export const AccordionItemStyled = styled(AccordionItem)`
  position: relative;
  inline-size: 100%;
`;

export const AccordionItemHeadingStyled = styled(AccordionItemHeading)`
  inline-size: 100%;
`;

export const ButtonInner = styled.span<MenuProps>`
  position: relative;
  ${AccordionLinkShared};
  min-block-size: 32px;
`;

export const AccordionItemButtonStyled = styled(AccordionItemButton)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;

  ${AccordionItemStyled} ${AccordionItemStyled} & {
    padding-inline-start: 20px;
  }
  ${AccordionItemStyled} ${AccordionItemStyled} ${AccordionItemStyled} & {
    padding-inline-start: 40px;
  }
`;

export const Chevron = styled(ChevronSvg).attrs({
  'aria-hidden': true,
  focusable: false,
  height: 16,
  width: 16,
})`
  display: block;
  position: absolute;
  right: 0;
  top: calc(50% - 8px);
  transition: all 0.1s ease-out;
  inline-size: 16px;
  block-size: 16px;
  pointer-events: none;

  [aria-expanded='true'] > & {
    content: '';
    transform: rotate(-180deg);
    transform-origin: 8px 8px;
  }
`;

interface AccordionItemPanelStyledProps {
  $height?: number;
}

export const AccordionItemPanelStyled = styled(
  AccordionItemPanel
)<AccordionItemPanelStyledProps>`
  position: relative;
  display: block;
  inline-size: 100%;
  overflow: hidden;

  &[hidden] {
    display: block;
  }
`;

export const AccordionItemContent = styled.div`
  padding-block: 8px;
`;
