import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/eripiowear/helpers';

export const ProductHeroStyled = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: thin solid ${colors.gray4};

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    padding: 0 20px;
    background-color: ${colors.gray5};
  }
`;

export const Inner = styled.div`
  display: grid;
  grid-template:
    'imgs imgs'
    'info info' auto / 50% 50%;
  width: 100%;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    min-block-size: 650px;
    max-inline-size: calc(1440px - 40px);
    grid-template-areas:
      'info imgs'
      'info imgs';
  }
`;

export const Carousel = styled.div`
  grid-area: imgs;
  overflow: hidden;
`;

export const InfoWrapper = styled.div`
  max-width: 500px;
  width: 100%;
`;

export const InfoContent = styled.div`
  padding: 20px;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    padding: 0;
  }
`;

export const InfoColors = styled.div`
  padding: 20px;
  border-top: thin solid ${colors.gray4};

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    padding: 0;
    border: 0;
  }
`;

export const InfoTab = styled.div`
  grid-area: info;
  display: flex;
  align-items: center;
`;

export const ProductCertificates = styled.div`
  margin-block-end: 8px;

  img {
    block-size: 24px;
    margin-inline-end: ${spacing.xSmall};
  }
`;

export const ProductHeroInfoText = styled.p`
  line-height: ${lineHeights.bodyMobile};
  font-size: 18px;
  font-weight: ${fontWeights.normal};
  margin-block: 0 24px;
`;

export const DetailListItem = styled.li`
  display: inline-flex;
  padding: 0;
  margin-block-end: ${spacing.regular};

  &::before {
    position: relative;
    content: '';
    display: flex;
    flex-shrink: 0;
    inline-size: 16px;
    block-size: 1px;
    background: ${colors.black};
    margin-inline-end: 8px;
    inset-block-start: 11px;
  }
`;

export const DetailList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: ${fontSizes.body};
  line-height: 24px;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    font-size: ${fontSizes.m};
  }
`;

export const TraitLabel = styled.div`
  position: relative;
  font-size: ${fontSizes.body};
  display: flex;
  align-items: center;

  &::after {
    content: ':';
  }

  &::before {
    position: relative;
    content: '';
    display: flex;
    flex-shrink: 0;
    inline-size: 16px;
    block-size: 1px;
    background: ${colors.black};
    margin-inline-end: 8px;
  }
`;

export const TraitList = styled.ul`
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
  gap: ${spacing.tinier};
`;

export const TraitItem = styled.li`
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${spacing.tinier};
  padding-block: ${spacing.tinier};
  padding-inline: ${spacing.xSmall};
  background-color: ${colors.gray5};
  border: thin solid ${colors.gray4};

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    background-color: ${colors.white};
    border: thin solid ${colors.gray4};
  }
`;

interface RoundedProps {
  $rounded?: boolean;
}

export const TraitImage = styled.img<RoundedProps>`
  width: 24px;
  height: 24px;
  ${({ $rounded }) =>
    $rounded
    && css`
      border-radius: 100%;
    `}
`;
