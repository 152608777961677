export const lineHeights = {
  // body: '20px',
  // Eripiowear
  body: '1',

  bodyDesktop: 1.625,

  bodyMobile: 1.7,

  contentDesktop: '1.625rem',

  contentMobile: '24px',

  header1: '42px',

  header1Content: '37px',

  header2: '36px',

  header2Content: '28px',

  header3: '24px',

  header3Content: '24px',

  header4: '28px',

  headline: '1.2',

  ingressDesktop: '1.75rem',

  l: 1.7,

  m: 1.5,

  preamble: '1.1',

  productDesktop: 1.3,

  productMobile: 1.4,
  s: 1.25,
  xs: 1,
} as const;

export type LineHeights = keyof typeof lineHeights;
